<template>
  <div id="petition">
    <v-expand-transition v-if="ready">
      <v-card class="mx-auto" color="#00b4d8" dark max-width="640">
        <v-card-title>
          <div class="text-h4" style="letter-spacing: 0 !important;">
            Save the Old Foundry
          </div>
          <div
            class="text-h5 font-weight-bold mostly-white"
            style="letter-spacing: 1px !important;"
          >
            Sign this petition and let them know you care!
          </div>
        </v-card-title>

        <v-divider color="#03045E"></v-divider>

        <v-card-text v-if="petitionCompleted">
          <p class="petition-text">
            Thank you for signing the petition, <strong>{{petitionCompleted}}</strong>!
          </p>
          <v-btn block @click="clearPetitionCompleted()">That's not me&mdash;I'd like to sign!</v-btn>
        </v-card-text>
        <v-card-text v-if="!petitionCompleted">
          <v-carousel
            v-model="slide"
            :height="height"
            :continuous="false"
            :show-arrows="false"
            hide-delimiter-background
            touchless
            @change="resizeTimeout"
          >
            <v-carousel-item>
              <v-card class="mx-auto" light ref="card0">
                <v-card-text>
                  <p class="petition-text">
                    Nihil fugit placeat modi nesciunt sed cum, atque quis,
                    magnam sit tempore obcaecati dicta est id, ipsa non labore
                    voluptate laudantium.
                  </p>
                  <v-text-field
                    filled
                    v-model="fName"
                    :error-messages="fNameErrors"
                    label="First name"
                    required
                    @input="$v.fName.$touch()"
                    @blur="$v.fName.$touch()"
                  ></v-text-field>
                  <v-text-field
                    filled
                    class="mt-2"
                    v-model="lName"
                    :error-messages="lNameErrors"
                    label="Last name"
                    required
                    @input="$v.lName.$touch()"
                    @blur="$v.lName.$touch()"
                  ></v-text-field>
                  <v-text-field
                    filled
                    class="mt-2"
                    v-model="email"
                    :error-messages="emailErrors"
                    label="Email address"
                    required
                    v-on:keyup.enter="slideNext"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                </v-card-text>
              </v-card>
              <v-spacer />
              <v-btn
                color="#03045E"
                large
                class="float-right mt-5 mr-2"
                @click="slideNext()"
                :disabled="nextDisabled1"
              >
                Continue
                <v-icon dark class="ml-2">mdi-arrow-right-bold</v-icon>
              </v-btn>
            </v-carousel-item>
            <v-carousel-item>
              <v-card class="mx-auto" light ref="card1">
                <v-card-text>
                  <p class="petition-text">
                    Do you live in Plymouth?
                  </p>
                  <v-radio-group v-model="liveHere" @change="changeRadio">
                    <v-radio
                      label="Yes, I live in Plymouth"
                      value="yes"
                    ></v-radio>
                    <v-radio
                      label="No, but I still really care about this issue"
                      value="no"
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition v-if="this.liveHere === 'yes'">
                    <div class="petition-text">
                      <v-divider color="#03045E"></v-divider>
                      <p class="mt-4 font-weight-black">Excellent!</p>
                      <p>
                        The feedback from folks who actually live in Plymouth is
                        especially powerful to the people at SHM and in the town
                        government, so you signing this petition is
                        extra-meaningful.
                      </p>
                      <p>Please enter your address in Plymouth.</p>
                      <v-autocomplete
                        :items="acAddresses"
                        :loading="acLoading"
                        hide-no-data
                        :search-input.sync="search"
                        ref="address"
                        filled
                        v-model="address"
                        :error-messages="addressErrors"
                        label="Address"
                        required
                        @keyup.enter="slideNext"
                        @input="$v.address.$touch()"
                        @blur="$v.address.$touch()"
                      ></v-autocomplete>
                    </div>
                  </v-expand-transition>
                  <v-expand-transition v-if="this.liveHere === 'no'">
                    <div class="petition-text">
                      <v-divider color="#03045E"></v-divider>
                      <p class="mt-4 font-weight-black">No worries!</p>
                      <p>
                        This issue affects more than just those of us who happen
                        to live in Plymouth. Thank you for signing our petition!
                      </p>
                      <p>
                        Out of curiosity, where are you from?
                      </p>
                      <v-text-field
                        filled
                        class="mt-2"
                        v-model="address"
                        label="City and State"
                        v-on:keyup.enter="slideNext"
                        @input="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                      ></v-text-field>
                    </div>
                  </v-expand-transition>
                </v-card-text>
              </v-card>
              <v-spacer />
              <v-btn
                color="#03045E"
                large
                class="float-right mt-5 mr-2"
                @click="slideNext()"
                :disabled="nextDisabled2"
              >
                Continue
                <v-icon dark class="ml-2">mdi-arrow-right-bold</v-icon>
              </v-btn>
            </v-carousel-item>
            <v-carousel-item>
              <v-card class="mx-auto" light ref="card2">
                <v-card-text>
                  <div class="petition-text" v-if="!emailMode">
                    <p class="mt-4 font-weight-black">
                      Thank you for signing our petition!
                    </p>
                    <p>
                      Just one more quick question&mdash;would you like us to
                      let you know when we have any new updates on what's going
                      on?
                    </p>
                    <v-btn
                      color="#03045E"
                      block
                      dark
                      large
                      class="mt-5"
                      @click="emailSignup()"
                    >
                      Yes! Keep me informed!
                      <v-icon dark class="ml-2">mdi-email-check</v-icon>
                    </v-btn>
                    <v-btn
                      color="#CAF0F8"
                      block
                      class="mt-2"
                      @click="emailDecline()"
                    >
                      No thanks, I'm good
                      <v-icon dark class="ml-2"
                        >mdi-email-remove-outline</v-icon
                      >
                    </v-btn>
                  </div>
                  <div class="petition-text" v-if="emailMode === 'signup'">
                    <p class="mt-4 font-weight-black">
                      Yay! You've been added to our mailing list.
                    </p>
                    <p>
                      We'll keep you informed on what's going on with yadda
                      yadda yadda. Thanks!
                    </p>
                  </div>
                  <div class="petition-text" v-if="emailMode === 'decline'">
                    <p class="mt-4 font-weight-black">
                      No worries! Thanks for signing our petition!
                    </p>
                    <p>
                      We promise never to contact you. Thanks again!
                    </p>
                  </div>
                </v-card-text>
              </v-card>
              <v-spacer />
              <v-btn
                color="#03045E"
                large
                class="float-right mt-5 mr-2"
                @click="close()"
              >
                Close
                <v-icon dark class="ml-2">mdi-close</v-icon>
              </v-btn>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

const defaultData = {
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
};

const acUrl =
  "https://api.geoapify.com/v1/geocode/autocomplete" +
  "?filter=circle:-70.65423205074603,41.912404399787306,20000" +
  "&apiKey=8b8b4ff40531444280dd59f8d4a150d5&text=";
const requestOptions = {
  method: "GET"
};

const dbUrl = "http://mcgregor.software:9910";

const debounceDelay = 1000;

export default {
  name: "Petition",
  data: () => ({
    ready: false,
    fName: "",
    lName: "",
    email: "",
    address: "",
    select: null,
    liveHere: null,
    height: 320,
    slide: 0,
    acLoading: false,
    acDebouncing: false,
    acDebounceTimeout: null,
    acAddresses: [],
    search: null,
    emailMode: null,
    dbId: null,
    petitionCompleted: false
  }),
  watch: {
    search(val) {
      val && val !== this.address && this.autocomplete(val);
    }
  },
  mixins: [validationMixin],
  validations: {
    fName: { required },
    lName: { required },
    email: { required, email },
    address: { required }
  },
  computed: {
    fNameErrors() {
      const errors = [];
      if (this.$v.fName.$dirty) {
        if (!this.$v.fName.required) {
          errors.push("First name is required.");
        }
      }
      return errors;
    },
    lNameErrors() {
      const errors = [];
      if (this.$v.lName.$dirty) {
        if (!this.$v.lName.required) {
          errors.push("Last name is required.");
        }
      }
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.address.$dirty) return errors;
      !this.$v.address.required && errors.push("Address is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    nextDisabled1() {
      return !!(
        !this.fName ||
        !this.lName ||
        !this.email ||
        this.emailErrors.length ||
        this.lNameErrors.length ||
        this.fNameErrors.length
      );
    },
    nextDisabled2() {
      if (this.liveHere === "no") {
        return false;
      } else if (this.liveHere === "yes" && this.address !== "") {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    changeRadio() {
      this.resizeTimeout();
      if (this.liveHere === "yes") {
        this.focus("address");
      }
    },
    focus(el) {
      if (this.$refs[el]) {
        this.$refs[el].focus();
      } else {
        setTimeout(() => {
          this.focus(el);
        }, 250);
      }
    },
    saveData() {
      if (this.dbId) {
        this.saveUpdatedData();
      } else {
        this.saveNewData();
      }
    },
    async saveNewData() {
      const data = {
        fName: this.fName,
        lName: this.lName,
        email: this.email,
        address: this.address,
        plymouth: this.liveHere,
        addToMailingList: this.emailMode === "signup"
      };
      const postData = { ...defaultData, body: JSON.stringify(data) };
      const rawResponse = await fetch(dbUrl, postData);
      const content = await rawResponse.json();
      this.dbId = content.id;
    },
    async saveUpdatedData() {
      const data = {
        id: this.dbId,
        fName: this.fName,
        lName: this.lName,
        email: this.email,
        address: this.address,
        plymouth: this.liveHere,
        addToMailingList: this.emailMode === "signup"
      };
      const url = `${dbUrl}/entry${this.dbId}`;
      const postData = { ...defaultData, body: JSON.stringify(data) };
      await fetch(url, postData);
    },
    slideNext() {
      if (this.slide === 0 && this.nextDisabled1) {
        return false;
      }
      if (this.slide === 1 && this.nextDisabled2) {
        return false;
      }
      this.slide++;
      this.saveData();
      this.resizeTimeout();
    },
    submit() {
      this.$v.$touch();
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    },
    resize() {
      if (this.$refs["card" + this.slide]?.$el) {
        const box = this.$refs["card" + this.slide].$el.getBoundingClientRect();
        this.height = box.height + 70;
      } else {
        setTimeout(this.resize, 250);
      }
    },
    resizeTimeout() {
      setTimeout(this.resize, 50);
      setTimeout(this.resize, 250);
      setTimeout(this.resize, 500);
    },
    close() {
      this.ready = false;
    },
    emailSignup() {
      console.log("email signup");
      this.emailMode = "signup";
      this.saveUpdatedData();
      this.setCompleted();
      this.resizeTimeout();
    },
    emailDecline() {
      console.log("email decline");
      this.emailMode = "decline";
      this.saveUpdatedData();
      this.setCompleted();
      this.resizeTimeout();
    },
    setCompleted() {
      localStorage.setItem("petitionCompleted", this.fName);
    },
    clearPetitionCompleted() {
      this.petitionCompleted = null;
      localStorage.removeItem("petitionCompleted");
    },
    autocomplete(str) {
      if (!this.acDebouncing) {
        this.acDebouncing = true;
        this.acDebounceTimeout = setTimeout(() => {
          this.acDebouncing = false;
        }, debounceDelay);
        this.acDebounced(str);
      } else {
        clearTimeout(this.acDebounceTimeout);
        this.acDebounceTimeout = setTimeout(() => {
          this.acDebouncing = false;
          this.acDebounced(str);
        }, debounceDelay);
      }
    },
    acDebounced(str) {
      this.acLoading = true;
      fetch(acUrl + str, requestOptions)
        .then(response => response.json())
        .then(result => this.acProcess(result))
        .catch(error => console.log("error", error));
    },
    acProcess(obj) {
      this.acLoading = false;
      if (obj.features) {
        this.acAddresses = [];
        obj.features.forEach(feature => {
          const p = feature?.properties || {};
          const address = p.formatted
            .split(", United States of America")
            .join("");
          this.acAddresses.push(address);
        });
      }
    }
  },
  mounted() {
    window.$pet = this;
    this.petitionCompleted = localStorage.getItem("petitionCompleted");
    this.ready = true;
    this.resize();
  }
};
</script>

<style lang="scss">
#petition {
  padding: 1em;
  .v-carousel__controls {
    justify-content: start;
    width: 50%;
    pointer-events: none;
  }
  .v-input__slot {
    margin-bottom: 2px !important;
  }
}
.petition-text {
  color: #000000aa;
  letter-spacing: 0;
  font-size: 18px;
}
</style>
